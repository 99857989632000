import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3c4858",
        secondary: "#4994ec",
        link: "#0F6EB5",
        yellow: "#f3ce49",
        red: "#ec5f59",
        red50: "#fad8d8",
        red500: "#d04748",
        error: "#E64646",
        success: "#3C8505",
        green: "#22C55E",
        bg: "#e3e8ef",
        gray50: "#F9FAFB",
        gray200: "#EAECF0",
        gray300: "#D0D5DD",
        gray400: "#98A2B3",
        gray500: "#667085",
        gray600: "#475467",
        gray700: "#344054",
        gray800: "#1D2939",
        gray900: "#101828",
        gray1: "#F8F8F8",
        gray2: "#E1E1E1",
        success50: "#ECFDF3",
        success500: "#12B76A",
        success700: "#027A48",
      },
    },
  },
  lang: {
    locales: {
      ru,
    },
    current: "ru",
  },
  icons: {
    values: {
      alertError: {
        component: () => import("@/components/icons/AlertError"),
      },
      alertSuccess: {
        component: () => import("@/components/icons/AlertSuccess"),
      },
      arrowLeft: {
        component: () => import("@/components/icons/ArrowLeft"),
      },
      arrowRight: {
        component: () => import("@/components/icons/ArrowRight"),
      },
      etryLogo: {
        component: () => import("@/components/icons/EtryLogo"),
      },
      account: {
        component: () => import("@/components/icons/IconAccount"),
      },
      accounts: {
        component: () => import("@/components/icons/IconAccounts"),
      },
      box: {
        component: () => import("@/components/icons/IconBox"),
      },
      briefcase: {
        component: () => import("@/components/icons/IconBriefcase"),
      },
      calendar: {
        component: () => import("@/components/icons/IconCalendar"),
      },
      dots: {
        component: () => import("@/components/icons/IconDots"),
      },
      fileChart: {
        component: () => import("@/components/icons/IconFileChart"),
      },
      fileClock: {
        component: () => import("@/components/icons/IconFileClock"),
      },
      close: {
        component: () => import("@/components/icons/IconClose"),
      },
      copy: {
        component: () => import("@/components/icons/IconCopy"),
      },
      document: {
        component: () => import("@/components/icons/IconDocument"),
      },
      help: {
        component: () => import("@/components/icons/IconHelp"),
      },
      monitor: {
        component: () => import("@/components/icons/IconMonitor"),
      },
      notebook: {
        component: () => import("@/components/icons/IconNotebook"),
      },
      notebook1: {
        component: () => import("@/components/icons/IconNotebook1"),
      },
      notebook2: {
        component: () => import("@/components/icons/IconNotebook2"),
      },
      object: {
        component: () => import("@/components/icons/IconObject"),
      },
      pencil: {
        component: () => import("@/components/icons/IconPencil"),
      },
      pin: {
        component: () => import("@/components/icons/IconPin"),
      },
      return: {
        component: () => import("@/components/icons/IconReturn"),
      },
      rocket: {
        component: () => import("@/components/icons/IconRocket"),
      },
      trash: {
        component: () => import("@/components/icons/IconTrash"),
      },
      upload: {
        component: () => import("@/components/icons/IconUpload"),
      },
      warehouse: {
        component: () => import("@/components/icons/IconWarehouse"),
      },
      warning: {
        component: () => import("@/components/icons/IconWarning"),
      },
      wrench: {
        component: () => import("@/components/icons/IconWrench"),
      },
      circleSuccess: {
        component: () => import("@/components/icons/IconCircleSuccess"),
      },
      circleError: {
        component: () => import("@/components/icons/IconCircleError"),
      },
      circleWarning: {
        component: () => import("@/components/icons/IconCircleWarning"),
      },
    },
  },
});
